var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid top-menos"},[_c('loading-overlay',{attrs:{"active":_vm.loading,"is-full-page":true,"loader":"bars"}}),_c('modalDetentionCrane',{attrs:{"modal":_vm.showModalDetentionCrane,"editModal":_vm.editModal},on:{"closeModal":function($event){_vm.showModalDetentionCrane=false},"child-refresh":function($event){_vm.datatableRefresh=true}}}),_c('CRow'),_c('CRow',[_c('CCol',{staticClass:"mt-4",attrs:{"sm":"12","lg":"5"}},[_c('CSelect',{attrs:{"label":_vm.$t('label.crane'),"horizontal":{ label: 'col-sm-12 col-lg-2 text-right', input: 'col-sm-12 col-lg-8'},"options":_vm.craneListFormatted},on:{"change":function($event){return _vm.getDetentionCraneList()}},model:{value:(_vm.VisitCraneId),callback:function ($$v) {_vm.VisitCraneId=$$v},expression:"VisitCraneId"}})],1),_c('CCol',{staticClass:"d-flex align-items-center justify-content-end mt-4",attrs:{"sm":"12","lg":"7"}},[_c('h6',{staticClass:"text-warning mr-3 mt-2"},[_c('CIcon',{staticClass:"mb-2 mr-1",attrs:{"name":"alertas","color":"wipe","size":"lg"}}),_vm._v(_vm._s(_vm.$t('label.currentTime'))+"  ")],1),_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
          content: _vm.$t('label.detentionCrane'),
          placement: 'top-end'
        }),expression:"{\n          content: $t('label.detentionCrane'),\n          placement: 'top-end'\n        }"}],attrs:{"square":"","color":"add"},on:{"click":function($event){_vm.showModalDetentionCrane=true; _vm.editModal=false}}},[_c('CIcon',{attrs:{"name":"cil-playlist-add"}}),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.$t('label.nueva')))])],1)],1),_c('CCol',{attrs:{"sm":"12"}},[_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"size":"lg","fields":_vm.fields,"items":_vm.computedItems,"items-per-page":5,"column-filter":"","pagination":"","table-filter":_vm.tableText.tableFilterText,"items-per-page-select":_vm.tableText.itemsPerPageText,"noItemsView":_vm.tableText.noItemsViewText,"sorter":""},scopedSlots:_vm._u([{key:"alertGifItem",fn:function({ item }){return [_c('td',{staticClass:"center-cell"},[(!item.TransactionFinish)?_c('div',{staticClass:"animation-item ml-2",staticStyle:{"display":"inline-block"}},[_c('h3',{staticClass:"text-danger"},[_c('CIcon',{staticStyle:{"width":"1.2rem","height":"1.2rem","font-size":"1.5rem"},attrs:{"name":"alertas","color":"danger"}})],1)]):_vm._e()])]}},{key:"Status",fn:function({item}){return [_c('td',{staticClass:"text-center"},[_c('CBadge',{attrs:{"color":_vm.getBadge(item.Status)}},[_vm._v(" "+_vm._s(_vm.$t('label.'+item.Status))+" ")])],1)]}},{key:"options",fn:function({ item }){return [_c('td',{staticClass:"center-cell"},[(item.FgActCraneEventGpo)?_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                content: _vm.$t('label.edit')+' '+_vm.$t('label.detentionCrane'),
                placement: 'top-end'
              }),expression:"{\n                content: $t('label.edit')+' '+$t('label.detentionCrane'),\n                placement: 'top-end'\n              }"}],staticClass:"mr-1",attrs:{"square":"","size":"sm","color":"edit"},on:{"click":function($event){_vm.showModalDetentionCrane=item; _vm.editModal=true}}},[_c('CIcon',{attrs:{"name":"pencil"}})],1):_vm._e(),(item.FgActCraneEventGpo)?_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                content: _vm.$t('label.delete')+' '+_vm.$t('label.detentionCrane'),
                placement: 'top-end'
              }),expression:"{\n                content: $t('label.delete')+' '+$t('label.detentionCrane'),\n                placement: 'top-end'\n              }"}],attrs:{"color":"wipe","size":"sm"},on:{"click":function($event){return _vm.DeleteDetentionCrane(item)}}},[_c('CIcon',{attrs:{"name":"cil-trash"}})],1):_vm._e()],1)]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }